export const makeInitialState = (location) => ({
  alertMessage: '',
  audioSrc: null,
  emotionData: null,
  emotionDataReady: false,
  gptRiskDescription: location.state?.content?.gpt_risk_desc || '',
  gptSentiment: location.state?.content?.gpt_sentiment ?? null,
  gptSummary: location.state?.content?.gpt_summary || null,
  highlights: location.state?.content?.highlights || null,
  isLoading: false,
  loadingLabel: null,
  loadingPercentage: location.state?.content?.loading_percentage || 0,
  metadata: null,
  metadataReady: false,
  personas: null,
  riskLabel: location.state?.content?.gpt_garm_level ?? null,
  riskLabelMap: null,
  sentimentLabelMap: null,
  showAlert: false,
  showProgressBar: false,
  source: location.state?.content?.source || null,
  timeoutId: null,
  topicData: null,
  topicDataReady: false,
  videoId: location.state?.content?.video_id || null,
  videoTitle: location.state?.contentTitle || null,
});

