import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { Stack, Grid } from '@mui/material';

import api, { Video } from 'api';
import { FILTER_KEYS, FilterKey, FiltersContext } from 'context/FiltersContext';
import {
  createStatsArray,
  formatNumberWithCommas,
  getContentUrl,
  getNavigationState,
  getRiskDescription,
  getSentimentDescription,
  initialState,
  riskColors,
  scrollToGallery,
  sentimentColors,
  transformAndCombineRiskData,
  transformCountMetricsData,
  transformPercentageMetricsData,
} from 'Helpers/batch';
import { EMOTION, GENRE, RISK } from 'context/FiltersContext';
import { debug, sum } from 'Helpers/utils';

import { DividedStats } from '../StatPanel/DividedStats';
import ChartAndStatsCell from './ChartAndStatsCell';
import InfographicAndStatsCell from './InfographicAndStatsCell';
import CircleAndStatsCell from './CircleAndStatsCell';
import Gallery from './Gallery';

const VIDEOS_PER_PAGE = 50;

interface BatchGalleryState {
  contentCount: number | null;
  emotionPieData: any | null;
  genrePieData: any[];
  hasMore: boolean;
  haveStats: boolean;
  isInitialLoad: boolean;
  isStatsLoading: boolean;
  isVideoLoading: boolean;
  paginationKey: string | null;
  riskDescription: React.ReactElement | null;
  riskPieData: any | null;
  sentimentDescription: React.ReactElement | null;
  sentimentPieData: any | null;
  sentimentStats: any[];
  themeStats: any[];
  toneStats: any[];
  totalEmotionCount: number;
  totalGenreCount: number;
  totalThemeCount: number;
  totalToneCount: number;
  uniquePublisherCount: number | null;
  videos: Video[];
}

type Filters = Partial<Record<FilterKey, string[]>>;

type FetchVideosResponse = {
  hasMore: boolean;
  paginationKey: string | null;
  videos: Video[];
}

const sortByValueDesc = (a, b) => b.value - a.value;

const fetchVideos = async ({
  batchId,
  limit,
  paginationKey,
  filters
}: {
  batchId: string,
  limit: number,
  paginationKey?: string,
  filters?: Filters
}): Promise<FetchVideosResponse> => {
  const recentVideosResponse = await api.mbs.getBatchVideos(batchId, paginationKey, limit, filters);
  debug('recentVideosResponse', recentVideosResponse);
  return {
    hasMore: recentVideosResponse.body.pagination_key !== null,
    paginationKey: recentVideosResponse.body.pagination_key,
    videos: recentVideosResponse.body?.videos || [],
  };
}

const BatchGallery = () => {
  const [searchParams] = useSearchParams();
  const [state, _setState] = useState<BatchGalleryState>(initialState);
  const setState = useCallback((newState: Partial<BatchGalleryState>) => {
    _setState((prevState) => ({ ...prevState, ...newState }));
  }, [_setState]);

  const {
    contentCount,
    emotionPieData,
    genrePieData,
    hasMore,
    haveStats,
    isInitialLoad,
    isStatsLoading,
    isVideoLoading,
    paginationKey,
    riskDescription,
    riskPieData,
    sentimentDescription,
    sentimentPieData,
    sentimentStats,
    themeStats,
    toneStats,
    totalEmotionCount,
    totalGenreCount,
    totalThemeCount,
    totalToneCount,
    uniquePublisherCount,
    videos,
  } = state;

  const { batchId } = useParams();
  const location = useLocation();
  const { initialize, setFilterByLabel } = useContext(FiltersContext);

  const clearVideos = () => {
    setState({ hasMore: false, paginationKey: null, videos: [] });
    debug('clearVideos');
  }

  const onFetchVideos = useCallback(({ hasMore, paginationKey, videos }: FetchVideosResponse) => {
    _setState((state) => {
      const videoSet = new Set(state.videos.map((video) => video.video_id));
      const uniqueNewVideos = videos.filter((video) => !videoSet.has(video.video_id));
      debug('uniqueNewVideos', uniqueNewVideos, hasMore, paginationKey);
      return {
        ...state,
        videos: [...state.videos, ...uniqueNewVideos],
        hasMore,
        paginationKey,
      };
    });
  }, []);

  // @TODO refactor
  const fetchBatchStats = useCallback(async (batchId) => {
    setState({ isStatsLoading: true });
    const batchStatsResponse = await api.mbs.getBatchStatistics(batchId);
    const statistics = batchStatsResponse.body?.statistics;
    const totalItems = statistics?.total_items;
    initialize(statistics);
    setState({
      contentCount: totalItems,
      uniquePublisherCount: statistics?.unique_channel_count
    });

    if (!totalItems) {
      setState({ isStatsLoading: false });
      return;
    }

    // sentiment
    const sentimentData = statistics?.sentiment?.data;
    const sentimentLabels = statistics?.sentiment?.labels;

    if (sentimentData && sentimentLabels) {
      const transformedSentimentData = transformPercentageMetricsData(
        sentimentData,
        sentimentLabels,
        totalItems,
        sentimentColors,
      );

      // Determine the sentiment with the highest value
      const highestSentimentIndex = sentimentData.indexOf(Math.max(...sentimentData));
      const highestSentimentLabel = sentimentLabels[highestSentimentIndex];
      const highestSentimentPercentage = parseFloat(((sentimentData[highestSentimentIndex] / totalItems) * 100).toFixed(2));

      const sentimentDescription = getSentimentDescription(
        highestSentimentLabel,
        highestSentimentPercentage,
      );

      setState({
        sentimentPieData: transformedSentimentData,
        sentimentStats: createStatsArray(sentimentLabels, sentimentData, 'Sentiment Description'),
        sentimentDescription,
      });
    } else {
      console.error('Sentiment data or labels are missing:', { sentimentData, sentimentLabels });
    }

    // risk
    const riskData = statistics?.risk_level?.data;
    const riskLabels = statistics?.risk_level?.labels;

    if (riskData && riskLabels) {
      const { data: updatedRiskData, labels: updatedRiskLabels } = transformAndCombineRiskData(
        riskData,
        riskLabels,
      );
      const transformedRiskPieData = transformPercentageMetricsData(
        updatedRiskData,
        updatedRiskLabels,
        totalItems,
        riskColors,
      );
      const highestRiskIndex = riskData.indexOf(Math.max(...riskData));
      const highestRiskLabel = riskLabels[highestRiskIndex];
      const highestRiskPercentage = parseFloat(((riskData[highestRiskIndex] / totalItems) * 100).toFixed(2));
      const riskDescription = getRiskDescription(highestRiskLabel, highestRiskPercentage);

      setState({
        riskDescription,
        riskPieData: transformedRiskPieData,
      });
    } else {
      console.error('Risk data or labels are missing:', { riskData, riskLabels });
    }

    //emotion
    const emotionData = statistics?.emotion?.data;
    const emotionLabels = statistics?.emotion?.labels;
    const combinedEmotionData = emotionData?.map((value, index) => ({
      label: emotionLabels[index],
      value: value,
    })) || [];

    // Sort the combined array by value in descending order
    combinedEmotionData.sort(sortByValueDesc);

    // Separate the sorted data back into emotionData and emotionLabels
    const topCombinedEmotionData = combinedEmotionData.slice(0, 8);
    const totalEmotionCount = emotionLabels.length;

    if (emotionData && emotionLabels) {
      const emotionPieData = transformCountMetricsData(
        topCombinedEmotionData.map((item) => item.value / totalItems),
        topCombinedEmotionData.map((item) => item.label),
      );
      debug('Transformed Emotion Data:', emotionPieData);
      setState({ emotionPieData, totalEmotionCount });
    } else {
      console.error('Emotion data or labels are missing');
    }

    //genre
    const genreData = statistics?.genre?.data;
    const genreLabels = statistics?.genre?.labels;
    const totalGenreCount = genreData.length;
    const combinedGenreData = genreData?.map((value, index) => ({
      label: genreLabels[index],
      value: value,
    }));
    combinedGenreData.sort(sortByValueDesc);

    const sortedGenreData = combinedGenreData.map((item) => item.value);
    const sortedGenreLabels = combinedGenreData.map((item) => item.label);
    const topCombinedGenreData = combinedGenreData.slice(0, 4);

    if (sortedGenreData && sortedGenreLabels) {
      const data = topCombinedGenreData.map((item) => item.value / totalItems);
      const labels = topCombinedGenreData.map((item) => item.label);
      const genrePieData = transformCountMetricsData(data, labels);
      setState({ genrePieData, totalGenreCount });
    } else {
      console.error('Emotion data or labels are missing');
    }

    const garmData = statistics?.garm_labels?.data;
    const garmLabels = statistics?.garm_labels?.labels;

    if (garmData && garmLabels) {
      const transformedGarmData = transformCountMetricsData(garmData, garmLabels);
      debug('Transformed Garm Data:', transformedGarmData);
    } else {
      console.error('Garm data or labels are missing:', { garmData, garmLabels });
    }

    //tone
    const toneData = statistics?.tone?.data;
    const toneLabels = statistics?.tone?.labels;
    const totalToneCount = toneLabels.length;

    if (toneData && toneLabels) {
      const toneStats = toneData.map((value, index) => ({
        label: toneLabels[index],
        value: value,
      }))
        .sort(sortByValueDesc)
        .slice(0, 6)
        .map(({ label, value }) => ({ label, value: (value / totalItems * 100).toFixed(1) + '%' }));

      setState({ toneStats, totalToneCount });
    } else {
      console.error('Emotion data or labels are missing');
    }

    //theme
    const themeData = statistics?.theme?.data;
    const themeLabels = statistics?.theme?.labels;

    const combinedThemeData = themeData
      .map((value, index) => ({ label: themeLabels[index], value: value }))
      .sort(sortByValueDesc)
      .map(({ label, value }) => ({ label, value: (value / totalItems * 100).toFixed(1) + '%' }));

    const sortedThemeData = combinedThemeData.map((item) => item.value);
    const sortedThemeLabels = combinedThemeData.map((item) => item.label);
    const totalThemeCount = sortedThemeLabels.length;
    const themeStats = combinedThemeData.slice(0, 5);
    if (sortedThemeData && sortedThemeLabels) {
      setState({ themeStats, totalThemeCount });
    } else {
      console.error('Emotion data or labels are missing');
    }

    setState({ isStatsLoading: false });
  }, [initialize, setState]);

  const getNavState = (video: Video) => getNavigationState(video, `${location.pathname}${location.search}`);

  const getFiltersFromSearchParams = (searchParams: URLSearchParams) =>
    Array.from(searchParams.entries())
      .filter(([key]) => FILTER_KEYS.includes(key as FilterKey))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: decodeURIComponent(value).split(',') }), {});

  const fetchAndSetVideos = async (paginationKey: string | null = null) => {
    const filters = getFiltersFromSearchParams(searchParams);
    const response = await fetchVideos({
      batchId,
      limit: VIDEOS_PER_PAGE,
      paginationKey,
      filters
    });
    onFetchVideos(response);
    debug('fetchAndSetVideos', response);
  }

  const onNext = () => {
    debug('onNext', hasMore, paginationKey);
    fetchAndSetVideos(paginationKey);
  };

  useEffect(
    function onSearchParamsChange() {
      clearVideos();
      fetchAndSetVideos();
      debug('onSearchParamsChange', searchParams);
    },
    [searchParams],
  );

  useEffect(() => {
    if (!haveStats) {
      fetchBatchStats(batchId);
      setState({ haveStats: true });
    }
  }, [batchId]);

  useEffect(() => {
    if (isInitialLoad) {
      setState({ isInitialLoad: isStatsLoading || isVideoLoading });
    }
  }, [isStatsLoading, isVideoLoading]);

  return (
    <Stack
      spacing={4}
      style={{
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        maxWidth: '100%',
      }}
    >
      <Stack
        sx={{
          maxWidth: { xs: '100%', md: '100%', lg: '80%' },
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {contentCount !== null && uniquePublisherCount && (
          <>
            <DividedStats
              stats={[
                { value: formatNumberWithCommas(contentCount), label: 'Pieces of Content' },
                { value: formatNumberWithCommas(uniquePublisherCount), label: 'Unique Placements' },
              ]}
            />
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <ChartAndStatsCell
                  title="Risk"
                  chartData={{
                    data: riskPieData,
                    chartType: 'pie',
                  }}
                  totalCount={0}
                  contentCount={contentCount}
                  description={riskDescription}
                  orientation="vertical"
                  onDataClick={(value) => {
                    setFilterByLabel(RISK, value);
                    scrollToGallery();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <CircleAndStatsCell
                  title="Theme"
                  topStats={themeStats}
                  totalCount={formatNumberWithCommas(totalThemeCount)}
                  onStatClick={(label) => {
                    setFilterByLabel('theme', label);
                    scrollToGallery();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <ChartAndStatsCell
                  title="Emotion"
                  chartData={{
                    data: emotionPieData,
                    chartType: 'bar',
                  }}
                  totalCount={formatNumberWithCommas(totalEmotionCount)}
                  contentCount={contentCount}
                  orientation="vertical"
                  onDataClick={(value) => {
                    setFilterByLabel(EMOTION, value);
                    scrollToGallery();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <ChartAndStatsCell
                  title="Genre"
                  chartData={{
                    data: genrePieData,
                    chartType: 'column',
                  }}
                  totalCount={formatNumberWithCommas(totalGenreCount)}
                  contentCount={contentCount}
                  descriptionPosition="top"
                  orientation="vertical"
                  titlePosition="bottom"
                  onDataClick={(value) => {
                    setFilterByLabel(GENRE, value);
                    scrollToGallery();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <ChartAndStatsCell
                  title="Sentiment"
                  chartData={{
                    data: sentimentPieData,
                    chartType: 'pie',
                  }}
                  topStats={sentimentStats}
                  totalCount={0}
                  contentCount={contentCount}
                  description={sentimentDescription}
                  orientation="vertical"
                  titlePosition="bottom"
                  descriptionPosition="top"
                  onDataClick={(value) => {
                    setFilterByLabel('sentiment', value);
                    scrollToGallery();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} sx={{ display: 'flex' }}>
                <InfographicAndStatsCell
                  descriptionPosition="top"
                  title="Tone"
                  titlePosition="bottom"
                  topStats={toneStats}
                  totalCount={formatNumberWithCommas(totalToneCount)}
                  onStatClick={(label) => {
                    setFilterByLabel('tone', label);
                    scrollToGallery();
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Stack>
      <Gallery
        hasMore={hasMore}
        getNavigationState={getNavState}
        getNavigationUrl={getContentUrl}
        isInitialLoading={false}
        isLoading={isStatsLoading || isVideoLoading}
        onNext={onNext}
        videos={videos}
        limit={999999}
      />
    </Stack>
  );
};

export default BatchGallery;
