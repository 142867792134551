import React, { useEffect, useState } from 'react';
import './VideoInfo/VideoInfo.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Box } from '@mui/material';

const YoutubeEmbed = React.memo(({ embedId }) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (embedId) {
      const timer = setTimeout(() => {
        setReady(true);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [embedId]);

  if (!embedId || !ready) {
    return null;
  }

  return (
    <div className="video-responsive">
      <iframe
        key={embedId}
        src={`https://www.youtube.com/embed/${embedId}?&autoplay=0&controls=1&rel=0&origin=mbs.themobian.com`}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
});

const TikTokEmbed = ({ embedId, url }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    script.onload = () => {
      if (window.Tiktok) {
        window.Tiktok.load();
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <blockquote
      className="tiktok-embed"
      cite={url}
      data-video-id={embedId}
      autoplay={0}
      style={{ maxWidth: '325px', minWidth: '325px' }}
    >
      <section />
    </blockquote>
  );
};

const XSocialEmbed = ({ embedId }) => {
  const cleanedTweetId = embedId.replace('xsocial_', '');

  return (
    <div className="embed-wrapper">
      <div className="twitter-embed-container">
        <TwitterTweetEmbed tweetId={cleanedTweetId} />
      </div>
    </div>
  );
};

const AudioEmbed = ({ audioSrc }) => {
  return (
    <div className="audio-embed-wrapper">
      <div className="audio-embed-container">
        <audio className="audio-player" controls src={audioSrc} />
      </div>
    </div>
  );
};

const ContentEmbed = ({ source, embedId, url = '' }) => {
  return (
    <div className="video-container">
      {source === 'youtube' && <YoutubeEmbed embedId={embedId} />}
      {source === 'tiktok' && (
        <Box width="100%" backgroundColor="white" borderRadius="6px">
          <TikTokEmbed embedId={embedId} url={url} />
        </Box>
      )}
      {source === 'xsocial' && (
        <Box width="100%" backgroundColor="white" borderRadius="6px">
          <XSocialEmbed embedId={embedId} />
        </Box>
      )}
      {source === 'omny' && (
        <Box width="100%" backgroundColor="white" borderRadius="6px">
          <AudioEmbed audioSrc={url} />
        </Box>
      )}
    </div>
  );
};

export default ContentEmbed;
