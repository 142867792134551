import React from "react";
import { Box } from "@mui/material";

export const sentimentColors = {
  Positive: '#3592fb',
  Negative: '#ed68a9',
  Neutral: '#54e1c6',
  Disingenuous: '#ffffff', //TODO: can remove once disingenuous sentiment is removed
  'No Content': '#a8a8a5',
};

export const riskColors = {
  High: '#f05e48',
  Medium: '#f3df4a',
  Low: '#6FCE3C',
  'No Content': '#a8a8a5',
};


export const formatNumberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const scrollToGallery = () => {
  const galleryContainer = document.getElementById('gallery-container');
  galleryContainer?.scrollIntoView({ behavior: 'smooth' });
}

export const initialState = {
  contentCount: null,
  emotionPieData: null,
  genrePieData: [],
  hasMore: true,
  haveStats: false,
  isInitialLoad: true,
  isStatsLoading: true,
  isVideoLoading: true,
  paginationKey: null,
  riskDescription: null,
  riskPieData: null,
  sentimentDescription: null,
  sentimentPieData: null,
  sentimentStats: [],
  themeStats: [],
  toneStats: [],
  totalEmotionCount: 0,
  totalGenreCount: 0,
  totalThemeCount: 0,
  totalToneCount: 0,
  uniquePublisherCount: null,
  videos: [],
};

const thresholds = [
  { threshold: 100, description: 'All' },
  { threshold: 95, description: 'Nearly all' },
  { threshold: 75, description: 'More than two thirds' },
  { threshold: 50, description: 'Over half' }
];

const getParsedPercentage = (percentage: number) => parseFloat(percentage.toString());

const getDescriptionText = (percentage: number) => {
  const parsedPercentage = getParsedPercentage(percentage);
  const config = thresholds.find(t => parsedPercentage >= t.threshold);
  return config?.description || `${parsedPercentage}%`
};

export const getRiskDescription = (label: string, percentage: number): JSX.Element => {
  const descriptionText = getDescriptionText(percentage);

  return (
    <>
      <Box component="span" fontWeight="fontWeightBold">
        {descriptionText}
      </Box>{' '}
      of the content had a {label.toLowerCase()} risk level.
    </>
  );
};

export const getSentimentDescription = (label: string, percentage: number): JSX.Element => {
  const descriptionText = getDescriptionText(percentage);

  return (
    <>
      <Box component="span" fontWeight="fontWeightBold">
        {descriptionText}
      </Box>{' '}
      of the content had {label.toLowerCase()} sentiment.
    </>
  );
};

export const getContentUrl = (content) => {
  switch (content.source) {
    case 'youtube':
      return `/content/${encodeURIComponent('https://www.youtube.com/watch?v=' + content.video_id)}`;
    case 'tiktok':
      return `/content/${encodeURIComponent(content.video_url)}`;
    case 'xsocial':
      return `/content/${encodeURIComponent(content.post_url)}`;
    case 'article':
    case 'iris_tv':
      return `/content/${encodeURIComponent(content.video_id)}`;
    case 'cloud_file_storage':
    case 'omny':
      return `/content/${content.video_id}`;
    default:
      return '';
  }
};

export const getNavigationState = (content, from: string) => {
  const baseState = { content, from };

  switch (content.source) {
    case 'youtube':
    case 'tiktok':
      return {
        ...baseState,
        creatorName: content.channel_name,
        contentTitle: content.video_title,
      };
    case 'xsocial':
      return {
        ...baseState,
        creatorName: content.channel_name,
        contentTitle: content.channel_nickname,
      };
    case 'article':
      return {
        ...baseState,
        creatorName: content.channel_id,
        contentTitle: content.article_title,
      };
    case 'iris_tv':
    case 'cloud_file_storage':
      return {
        ...baseState,
        contentTitle: content.video_title,
      };
    case 'omny':
      return {
        ...baseState,
        contentTitle: content.podcast_title,
        creatorName: content.channel_name,
      };
    default:
      return baseState;
  }
};

export const createStatsArray = (labels, data, description) => {
  return labels.map((label, index) => ({
    label,
    value: data[index],
    description,
  }));
};

export const transformPercentageMetricsData = (data, labels, contentCount, colorDict) => {
  if (!data || !labels || data.length !== labels.length) {
    return [];
  }

  return data.map((value, index) => {
    const guide = {
      'Metric Name': labels[index],
      'Value Type': 'Percentage',
      Color: colorDict[labels[index]] || undefined,
    };

    return {
      guide,
      value: value / contentCount,
    };
  });
};

export const transformAndCombineRiskData = (data, labels) => {
  if (!data || !labels) {
    console.error('Data or labels are undefined:', { data, labels });
    return { data: [], labels: [] };
  }

  let lowRiskIndex = labels.indexOf('Low');
  let noRiskIndex = labels.indexOf('No');

  // Combine "No Risk" into "Low Risk"
  if (noRiskIndex !== -1) {
    if (lowRiskIndex !== -1) {
      data[lowRiskIndex] += data[noRiskIndex];
    } else {
      labels.push('Low Risk');
      data.push(data[noRiskIndex]);
    }
    labels.splice(noRiskIndex, 1);
    data.splice(noRiskIndex, 1);
  }

  console.log('Transformed risk data:', { data, labels });
  return { data, labels };
};

export const transformCountMetricsData = (data, labels) => {
  if (!data || !labels || data.length !== labels.length) {
    console.error('Data or labels are invalid:', { data, labels });
    return [];
  }

  return data.map((value, index) => {
    return {
      guide: {
        'Metric Name': labels[index],
        'Value Type': 'Percentage',
      },
      value: value,
    };
  });
};
